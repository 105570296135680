import React from 'react'
import { Link } from 'react-router-dom'
import { servicesBanner, servicesFive, servicesFour, servicesOne, servicesSaven, servicesSix, serviceEleven ,servicesThree, servicesTwo } from '../../Assets'
import { Layout } from '../../Components'

export default function Services() {
  return (
    <Layout>
        <section className='relative z-10 md:pb-10 banner'>
            <div className='relative'>
                <img className='object-cover min-h-[200px] w-full max-h-[250px]' src={servicesBanner} alt={``}/>

                <div className='container absolute grid lg:grid-cols-5 -translate-x-1/2 -translate-y-1/2 top-1/2 left-2/4'>
                    <div className='lg:col-span-2'>
                        <h1 className='lg:text-5xl md:text-4xl text-3xl font-bold text-slate-800'>
                        Services
                        </h1>

                        <div className='flex pt-6 divide-x divide-slate-300'>
                            <Link to={`/`} className='inline-block pr-5 text-indigo-500'>
                                Home
                            </Link>
                            <p className='pl-5'>Services</p>
                        </div>
                        

                    </div>
                </div>
            </div>
        </section>

        <section className='lg:py-20 py-10'>
            <div className='container'>
                <div className='grid lg:grid-cols-2 gap-5 gap-x-20'>
                    <div>
                        <p className='pb-5 lg:text-4xl text-3xl font-semibold text-slate-800'>Product & Platform <span className='text-indigo-500'>Engineering</span></p>
                        <p className='pb-4 text-sm font-light leading-6'>
                            Engineering next-generation software products and platforms at breakneck speed with our software product engineering DNA.
                        </p>
                        <p className='pb-4 text-sm font-light leading-6'>
                            <span className='font-semibold'>1 Microservices:</span> Developing programs with an architectural strategy that enables elements of an app to work separately, yet together.
                        </p>
                        <p className='pb-4 text-sm font-light leading-6'>
                            Microservices-based architecture for improved scalability, clearer coding, and faster provisioning with a smoother transition from legacy monoliths.

                        </p>
                        <p className='pb-4 text-sm font-light leading-6'>
                            Providing self-service installations for infrastructure and applications, as well as ease of operating applications in production, with the reduction in time to market and complexity.
                        </p>
                        <p className='pb-4 text-sm font-light leading-6'>
                        <span className='font-semibold'>CloudOps: </span> 
                        All cloud operations like cloud migration, deployment, and single & multi-cloud environments orchestration are made easier with greater flexibility, and scalability. Implementing the CloudOps help you to accelerate automation, enhance security, improve RPO/RTO, and on-demand scalability, and increase overall IT savings.
                        </p>
                    </div>

                    <div>
                        <img className='' src={servicesOne} alt={``}/>
                    </div>
                </div>
            </div>
        </section>

        <section className='lg:py-20 py-10 bg-slate-50'>
            <div className='container'>
                <div className='grid lg:grid-cols-2 gap-5 gap-x-20'>
                    <div>
                        <img className='' src={servicesTwo} alt={``}/>
                    </div>
                    <div>
                        <p className='pb-5 lg:text-4xl text-3xl font-semibold text-slate-800'>Data  <span className='text-indigo-500'>Services</span> </p>
                        <p className='pb-4 text-sm font-light leading-6'>
                        One of the main obstacles for many companies is their own IT. A data and analytics strategy is not unlocked by legacy systems, fragmented technology, and localized, difficult-to-share data; rather, they are hampered by them. Few businesses have the luxury of delaying a new IT infrastructure for years. No business can afford to do nothing.
                        </p>
                        <p className='pb-4 text-sm font-light leading-6'>
                        By developing a data and digital platform, we hasten transformation driven by data. It decouples data from old systems, making it easily accessible for new applications and business models. This serves as a launching pad for data and analytics. Companies may conceive large, start small, and grow quickly using a data and digital platform since they are not constrained by their IT infrastructure or the rate of its replacement.
                        </p>
                        <p className='pb-4 text-sm font-light leading-6'>
                            <span className='font-semibold'>Data Governance:</span> Data governance is a set of guidelines and procedures that guarantees high quality for your data throughout its entire lifecycle. Our services will ensure all your data is carefully vetted, neatly arranged, and ready for you to explore the insights.

                        </p>
                        
                    </div>
                </div>
            </div>
        </section>

        <section className='lg:py-20 py-10'>
            <div className='container'>
                <div className='grid lg:grid-cols-2 gap-5 gap-x-20'>
                    <div>
                        <p className='pb-5 lg:text-4xl text-3xl font-semibold text-slate-800'>Cloud <span className='text-indigo-500'>Engineering</span> </p>
                        <p className='pb-4 text-sm font-light leading-6'>
                        Re-platform, re-host, and re-engineer your application from legacy monoliths 
                        to the cloud and leverage the cloud’s capability to solve complex business problems.
                        </p>
                        <p className='pb-4 text-sm font-light leading-6'>
                            <span className='font-semibold'>Serverless: </span> 
                            Enable your developers to create and execute services without having to handle the underlying infrastructure. While developers build and deploy code, 
                            cloud providers offer servers to operate their applications, databases, and storage systems of any size.
                        </p>

                        <p className='pb-4 text-sm font-light leading-6'>
                            <span className='font-semibold'>Application Modernization: </span> 
                            Why still depend on Monolithic legacy systems when application modernization helps you to adapt to the latest digital industry standards?
                        </p>
                        <p className='pb-4 text-sm font-light leading-6'>
                        Re-platform, re-host, and re-engineer your application from legacy monoliths to the cloud and leverage the cloud’s capability to solve complex business problems.
                        </p>
                        <p className='pb-4 text-sm font-light leading-6'>
                        Migrate all your existing code using the Lift and Shift method without any hassle to leverage the most cost-efficient cloud infrastructure.
                        </p>
                        
                    </div>

                    <div>
                        <img className='' src={servicesThree} alt={``}/>
                    </div>
                </div>
            </div>
        </section>

        <section className='lg:py-20 py-10 bg-slate-50'>
            <div className='container'>
                <div className='grid lg:grid-cols-2 gap-5 gap-x-20'>
                    

                    <div>
                        <img className='' src={servicesFour} alt={``}/>
                    </div>
                    <div>
                        <p className='pb-5 lg:text-4xl text-3xl font-semibold text-slate-800'>Security <span className='text-indigo-500'> Services</span> </p>
                        <p className='pb-4 text-sm font-light leading-6'>
                            Make your data and application readily available to authorized users with enhanced security controls.
                        </p>
                        <p className='pb-4 text-sm font-light leading-6'>
                            <span className='font-semibold'>Cybersecurity challenges demand an organization to face two facts: </span> 
First, the protection of critical company and customer information which is a business requirement to protect the company’s reputation and enterprise value.

Second, even the best programs will experience failure and expose some information the company would like to protect.
                        </p>
                        <p className='pb-4 text-sm font-light leading-6'>
                        We believe confidence in security and privacy does not come from knowing nothing will happen, it is achieved by knowing all the things that can happen and preparing both proactive and reactive solutions.
                        </p>
                        <p className='pb-4 text-sm font-light leading-6'>
                        Holistic approach starts by understanding what is most important to you, also as applicable to your business such as PCI DSS, SOC 1, HIPAA, then structuring and supporting the program so that your business is engineered to grow securely.
                        </p>
                    </div>
                </div>
            </div>
        </section>


        <section className='lg:py-20 py-10'>
            <div className='container'>
                <div className='grid lg:grid-cols-2 gap-5 gap-x-20'>
                    <div>
                        <p className='pb-5 lg:text-4xl text-3xl font-semibold text-slate-800'>Payment  <span className='text-indigo-500'>Consulting</span> </p>
                        <p className='pb-4 text-sm font-light leading-6'>
                        Our technology consulting practice provides a foundation stone for launching a broader technology initiative, 
                        whether it is to drive innovation on proprietary & legacy infrastructures, reduce operational bottlenecks, 
                        mitigate risks from outsourced application development and/or bridging the people, process, technology gap 
                        that might exist currently at customer organizations. If you look at the current state of payment technology 
                        initiatives within the retail and payment markets and the challenges faced by them – it is easy to notice the 
                        need for a payments technology practice which not only is proficient in software development but is also extremely 
                        proficient in engaging with customers much before software development actually starts. A 360 degree view of software
                         engineering for payments can only be provided by an organization which already understands the business going in – at 
                         PayCube we believe that there are 3 clear engagement points:
                        </p>
                       
                    </div>

                    <div>
                        <img className='' src={servicesFive} alt={``}/>
                    </div>
                </div>
            </div>
        </section>

        <section className='lg:py-20 py-10 bg-slate-50'>
            <div className='container'>
                <div className='grid lg:grid-cols-2 gap-5 gap-x-20'>
                    

                    <div>
                        <img className='' src={servicesSix} alt={``}/>
                    </div>
                    <div>
                        <p className='pb-5 lg:text-4xl text-3xl font-semibold text-slate-800'>Staff  <span className='text-indigo-500'> Augmentation</span> </p>
                        <p className='pb-4 text-sm font-light leading-6'>
                            IT Skills are diverse and the nature of technology initiatives can warrant the need for short or 
                            long term skills which might not exist within a customer’s current environment. 
                            PayCube with its focus on the domain and the ability to mobilize resources on short notice - participates closely with its customers to fill these gaps in terms of staff augmentation and contingent workforce management.
                        </p>
                        
                        <ul className='list-items'>
                            <li>
                            Rigorous intake process by our highly skilled technical team
                            </li>
                            <li>
                            The consultants supported by the larger technical team
                            </li>
                            <li>
                            The consultants work in US overlapping hours (open for negotiation)
                            </li>
                            <li>
                            Detailed background check (being a payments company, it's a must)
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>


        <section className='lg:py-20 py-10'>
            <div className='container'>
                <div className='grid lg:grid-cols-2 gap-5 gap-x-20'>
                    <div>
                        <p className='pb-5 lg:text-4xl text-3xl font-semibold text-slate-800'>Blockchain </p>
                        <p className='pb-4 text-sm font-light leading-6'>
                        Innowise Group reinforces businesses with world-class blockchain solutions. We draw upon the profound knowledge of blockchain to automate, secure and accelerate operations. Our team assists businesses at each step of upgrading their processes with the most innovative technologies.
                        </p>
                        <p className='pb-4 text-sm font-light leading-6'>
                            <span className='font-semibold'>Smart contracts :</span> Utilize blockchain smart contracts to max out the efficiency, speed, and security of transaction-based processes. Our talented blockchain developers build and test custom decentralized solutions automating data transactions and eliminating paperwork to reduce the costs and improve the security of the processes.
                        </p>
                        <p className='pb-4 text-sm font-light leading-6'>
                            <span className='font-semibold'>Decentralized applications (DApps) :</span> Innowise Group combines the efficiency of smart contracts with a user-friendly interface resulting in the creation of a decentralized peer-to-peer network. It doesn’t have a point of failure presented by the hosting server, which means eradicating downtime and operational restrictions.
                        </p>
                        <p className='pb-4 text-sm font-light leading-6'>
                            <span className='font-semibold'>CEX/DEX platforms :</span> Whether the plan is to create a centralized or decentralized platform for cryptocurrency exchange, Innowise Group will cover everything from designing the UI to implementing niche features alongside the world’s best security and KYC practices.
                        </p>
                    </div>

                    <div>
                        <img className='' src={servicesSaven} alt={``}/>
                    </div>
                </div>
            </div>
        </section>


    </Layout>
  )
}
