import React from 'react';
import { Link } from 'react-router-dom';
import { contactBanner } from '../../Assets';
import { Layout } from '../../Components';
import {FiPhoneCall, FiMail} from 'react-icons/fi';
import {GoLocation} from 'react-icons/go';

export default function ContactUs() {
  return (
    <Layout>
        <section className='relative z-10 md:pb-10 banner'>

            <div className='relative'>
                <img className='object-cover min-h-[200px] w-full max-h-[250px]' src={contactBanner} alt={``}/>

                <div className='container absolute grid lg:grid-cols-5 -translate-x-1/2 -translate-y-1/2 top-1/2 left-2/4'>
                    <div className='lg:col-span-2'>
                        <h1 className='lg:text-5xl md:text-4xl text-3xl font-bold text-slate-800'>
                            Contact Us
                        </h1>

                        <div className='flex pt-6 divide-x divide-slate-300'>
                            <Link to={`/`} className='inline-block pr-5 text-indigo-500'>
                                Home
                            </Link>
                            <p className='pl-5'>Contact Us</p>
                        </div>
                        

                    </div>
                </div>
            </div>
        </section>

        <section className='container lg:py-20 py-10'>
            <div className='md:p-10 p-5 bg-slate-800 max-w-5xl mx-auto'>
                <p className='lg:text-4xl text-3xl font-semibold text-center text-white'>Get in Touch</p>
                <div className='grid sm:grid-cols-2 gap-5 pt-5'>
                  <div>
                    <input type={'text'} className={`form-control`} placeholder="Name"/>
                  </div>
                  <div>
                    <input type={'text'} className={`form-control`} placeholder="Email"/>
                  </div>
                  <div className='col-span-full'>
                    <input type={'text'} className={`form-control`} placeholder="Phone"/>
                  </div>
                  <div className='col-span-full'>
                    <textarea type={'text'} className={`form-control`} placeholder="Message"></textarea>
                  </div>
                  <div className='col-span-full text-center'>
                    <button className='inline-block px-8 py-2.5 mt-3 font-medium text-white uppercase rounded bg-gradient-to-r from-pink-500 to-indigo-500 hover:from-purple-500 hover:to-indigo-500'>
                      Submit
                    </button>
                  </div>
                </div>
            </div>

        </section>

        <section className='lg:py-20 py-10 bg-slate-50 '>
            <div className='container grid lg:grid-cols-5 md:grid-cols-5 gap-3'>
                <div className='bg-white rounded-lg p-6 shadow-xl'>
                    <p className='text-xl font-semibold'>Canada</p>
                    <ul className='pt-5 space-y-4'>
                        <li className='flex  items-center text-sm'>
                            <span className='text-indigo-500 pr-2.5'>
                                <FiPhoneCall size={18}/>
                            </span>
                            <span>
                                +1 (604) 499-2474 (call/whatsapp) 
                            </span>
                        </li>

                        <li className='flex  items-center'>
                            <span className='text-indigo-500 pr-2.5'>
                                <FiMail size={18}/>
                            </span>
                            <span>
                               admin@softgems.net
                            </span>
                        </li>

                        <li className='flex  items-center'>
                            <span className='text-indigo-500 pr-2.5'>
                                <GoLocation size={18}/>
                            </span>
                            <span>
                                15731 92 Ave, Surrey, BC V4N 3C3
                            </span>
                        </li>
                    </ul>
                </div>

                <div className='bg-white rounded-lg p-6 shadow-xl'>
                    <p className='text-xl font-semibold'>USA</p>
                    <ul className='pt-5 space-y-4'>
                        <li className='flex  items-center text-sm'>
                            <span className='text-indigo-500 pr-2.5'>
                                <FiPhoneCall size={18}/>
                            </span>
                            <span>
                                +1 (604) 499-2474 (call/whatsapp)
                            </span>
                        </li>

                        <li className='flex  items-center'>
                            <span className='text-indigo-500 pr-2.5'>
                                <FiMail size={18}/>
                            </span>
                            <span>
                               admin@softgems.net
                            </span>
                        </li>

                        <li className='flex  items-center'>
                            <span className='text-indigo-500 pr-2.5'>
                                <GoLocation size={18}/>
                            </span>
                            <span>
                                100 N HOWARD ST STE R SPOKANE WA 99201
                            </span>
                        </li>
                    </ul>
                </div>
                <br></br>
                <div className='bg-white rounded-lg p-6 shadow-xl'>
                    <p className='text-xl font-semibold'>India</p>
                    <ul className='pt-5 space-y-4'>
                        <li className='flex  items-center text-sm'>
                            <span className='text-indigo-500 pr-2.5'>
                                <FiPhoneCall size={18}/>
                            </span>
                            <span>
                                
                                +91 9831438858 (call/whatsapp)
                            </span>
                        </li>
                        <li className='flex  items-center text-sm'>
                            <span className='text-indigo-500 pr-2.5'>
                                <FiPhoneCall size={18}/>
                            </span>
                            <span>
                                
                            +91 9163338223 (call/whatsapp)
                            </span>
                        </li>

                        <li className='flex  items-center'>
                            <span className='text-indigo-500 pr-2.5'>
                                <FiMail size={18}/>
                            </span>
                            <span>
                               admin@softgems.net
                            </span>
                        </li>

                        <li className='flex  items-center'>
                            <span className='text-indigo-500 pr-2.5'>
                                <GoLocation size={18}/>
                            </span>
                            <span>
                                4ES8C, Mani Casadona, International Finalcial Hub(CBD), New Town, West Bengal 700156
                            </span>
                        </li>
                    </ul>
                </div>
                <div className='bg-white rounded-lg p-6 shadow-xl'>
                    <p className='text-xl font-semibold'>Dubai</p>
                    <ul className='pt-5 space-y-4'>
                        <li className='flex  items-center text-sm'>
                            <span className='text-indigo-500 pr-2.5'>
                                <FiPhoneCall size={18}/>
                            </span>
                            <span>
                                
                              +971 50 667 4537 (call/whatsapp)
                            </span>
                        </li>

                        <li className='flex  items-center'>
                            <span className='text-indigo-500 pr-2.5'>
                                <FiMail size={18}/>
                            </span>
                            <span>
                               admin@softgems.net
                            </span>
                        </li>

                        <li className='flex  items-center'>
                            <span className='text-indigo-500 pr-2.5'>
                                <GoLocation size={18}/>
                            </span>
                            <span>
                            Hexus Technologies<br></br>
                            Floor no 12, Rolex Tower, Sheikh Zayed Road,
                            Dubai, United Arab Emirates 
                            </span>
                        </li>
                    </ul>
                </div>
            </div>

        </section>
        
        {/* <section className='lg:py-20 py-10'>
            
            <div className='container grid lg:grid-cols-3 md:grid-cols-2 gap-10'>

                <p className='lg:text-4xl text-3xl font-semibold lg:pb-4 text-center col-span-full'>Our Esteemed <span className='text-indigo-500'> Partners</span></p>
                <div className='px-5'>
                    <p className='text-xl font-semibold'>Knowledge Solutions Group (YK), Japan</p>
                    <ul className='pt-5 space-y-4'>
                        <li className='flex  items-center text-sm'>
                            <span className='text-indigo-500 pr-2.5'>
                                <FiPhoneCall size={18}/>
                            </span>
                            <span>
                                +81 (3) 5297 0676
                            </span>
                        </li>

                        <li className='flex  items-center'>
                            <span className='text-indigo-500 pr-2.5'>
                                <FiMail size={18}/>
                            </span>
                            <span>
                                info@ksgcorp.co.jp
                            </span>
                        </li>

                        <li className='flex  items-center'>
                            <span className='text-indigo-500 pr-2.5'>
                                <GoLocation size={18}/>
                            </span>
                            <span>
                                Fujino Building, 5F, 1-13-5 Kanda Sudacho, Chiyoda-ku, Tokyo 101-0041, Japan
                            </span>
                        </li>
                    </ul>
                </div>

                <div className='px-5'>
                    <p className='text-xl font-semibold'>Knowledge Solutions Group LLC, USA</p>
                    <ul className='pt-5 space-y-4'>
                        <li className='flex  items-center text-sm'>
                            <span className='text-indigo-500 pr-2.5'>
                                <FiPhoneCall size={18}/>
                            </span>
                            <span>
                                +1 (407) 768 3838
                            </span>
                        </li>

                        <li className='flex  items-center'>
                            <span className='text-indigo-500 pr-2.5'>
                                <FiMail size={18}/>
                            </span>
                            <span>
                            info@ksgcorp.com
                            </span>
                        </li>

                        <li className='flex  items-center'>
                            <span className='text-indigo-500 pr-2.5'>
                                <GoLocation size={18}/>
                            </span>
                            <span>
                            118 Penguin Court Santa Cruz, CA 95060-2360
                            </span>
                        </li>
                    </ul>
                </div>

                <div className='px-5'>
                    <p className='text-xl font-semibold'>Knowledge Solutions Group, (Hong Kong) Limited</p>
                    <ul className='pt-5 space-y-4'>
                        <li className='flex  items-center text-sm'>
                            <span className='text-indigo-500 pr-2.5'>
                                <FiPhoneCall size={18}/>
                            </span>
                            <span>
                                +852 5808 6464
                            </span>
                        </li>
                        <li className='flex  items-center text-sm'>
                            <span className='text-indigo-500 pr-2.5'>
                                <FiPhoneCall size={18}/>
                            </span>
                            <span>
                                
                            +91 9163338223 (call/whatsapp)
                            </span>
                        </li>

                        <li className='flex  items-center'>
                            <span className='text-indigo-500 pr-2.5'>
                                <FiMail size={18}/>
                            </span>
                            <span>
                            info@ksgcorp.com.hk
                            </span>
                        </li>

                        <li className='flex  items-center'>
                            <span className='text-indigo-500 pr-2.5'>
                                <GoLocation size={18}/>
                            </span>
                            <span>
                            Unit 19, 8/F, Tai Tak Industrial Building, 2-12 Kwai Fat Road, Kwai Chung, New Territories, Kowloon, Hong Kong
                            </span>
                        </li>
                    </ul>
                </div>
            </div>

        </section>
         */}
    </Layout>
  )
}
