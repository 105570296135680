import React, { useState } from 'react'
import Slider from 'react-slick';
import {BannerOne, BannerThree, BannerTwo, BlogImageOne, BlogImageThree, BlogImageTwo, IconFive, IconFour, IconOne, IconSaven, IconSix, IconThree, IconTwo, ImageOne, servicesFive, servicesFour, servicesOne, servicesSaven, servicesSix, serviceEleven, servicesThree, servicesTwo} from  '../../Assets';
import { Link, useNavigate } from 'react-router-dom';
import { Disclosure, Transition } from '@headlessui/react';
import { useFormik } from 'formik';
import { Layout } from '../../Components';
import validator from 'validator';

export default function HomePage(){

  const [serOne, setSerOne] = useState(false);
  const [serTwo, setSerTwo] = useState(false);
  const [serThree, setSerThree] = useState(false);
  const [serFour, setSerFour] = useState(false);
  const [serFive, setSerFive] = useState(false);
  const [serSix, setSerSix] = useState(false);
  const [serSaven, setSerSaven] = useState(false);
  

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          arrows:false,
        }
      }
    ]
  };

  const blogSettings = {
    dots: false,
    infinite: true,
    arrows:false,
    autoplay: true,
    centerPadding: "60px",
    speed: 2000,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  }



  return (
    <Layout>
      <section className='relative z-10 pb-5 lg:pb-6 banner'>

        <Slider {...settings}>
          <div className='relative'>
            <img className='object-cover min-h-[400px] w-full max-h-[500px]' src={BannerTwo} alt={``}/>
        
            <div className='container absolute grid -translate-x-1/2 -translate-y-1/2 lg:grid-cols-5 top-1/2 left-2/4'>
                <div className='lg:col-span-3'>
                    <h1 className='text-3xl font-bold uppercase lg:text-5xl md:text-4xl text-slate-800'>
                      <span className='block text-indigo-500'>SOFTGEMS </span>
                    </h1>
                    <p className='pt-5 text-base font-semibold'>
                    We specialize in custom software development for a wide range of industries, including finance, healthcare, e-commerce, and more. Our team of expert developers will work closely with you to understand your unique business needs and develop software solutions that are tailored to your specific requirements.</p>
                    <p className='pt-5 text-base font-semibold'>
                    We use the latest technologies and methodologies to ensure that our software is efficient, scalable, and easy to maintain. Our agile development process means that we can deliver high-quality software quickly and efficiently, with regular updates and feedback to ensure that the final product meets your expectations.</p>
                    <p className='pt-5 text-base font-semibold'>
                    We offer flexible engagement models to suit your needs, including fixed-price projects, time and materials engagements, and dedicated development teams. Our services include full-cycle software development, software maintenance and support, and software testing and quality assurance.</p>
                    <p className='pt-5 text-base font-semibold'>
                    With our commitment to quality, reliability, and customer satisfaction, you can trust us to deliver software solutions that will help you achieve your business goals.
                    </p>
                    {/* <Link className='inline-block px-6 py-2.5 mt-10 font-medium text-white uppercase rounded bg-gradient-to-r from-pink-500 to-indigo-500 hover:from-purple-500 hover:to-indigo-500'>
                      READ MORE
                    </Link> */}

                </div>
            </div>
          </div>

          <div className='relative'>
            <img className='object-cover min-h-[400px] w-full max-h-[500px]' src={BannerThree} alt={``}/>
        
            <div className='container absolute grid -translate-x-1/2 -translate-y-1/2 lg:grid-cols-5 top-1/2 left-2/4'>
                <div className='lg:col-span-3'>
                    <h1 className='text-3xl font-bold uppercase lg:text-5xl md:text-4xl text-slate-800'>
                      <span className='block text-indigo-500'>SOFTGEMS </span>
                    </h1>
                    <p className='pt-5 text-base font-semibold'>
                    As a cloud service provider, we offer a wide range of cloud-based solutions to meet your business needs. Whether you require storage, computing power, or other IT resources, we have the infrastructure and expertise to support your organization. Our solutions are flexible and scalable, allowing you to easily adjust resources as your business grows and changes.</p>
                    <p className='pt-5 text-base font-semibold'>
                    We prioritize the security and reliability of our cloud services, providing robust data protection and disaster recovery capabilities to ensure your business operations run smoothly. Our team of experts are available 24/7 to provide support and assistance whenever you need it.</p>
                    <p className='pt-5 text-base font-semibold'>
                    We also offer competitive pricing and flexible payment options, enabling you to optimize your IT budget while accessing the latest cloud technology. Trust us to provide the infrastructure and support you need to drive your business forward in today's fast-paced digital landscape.
                    </p>
                    {/* <Link className='inline-block px-6 py-2.5 mt-10 font-medium text-white uppercase rounded bg-gradient-to-r from-pink-500 to-indigo-500 hover:from-purple-500 hover:to-indigo-500'>
                      READ MORE
                    </Link> */}

                </div>
            </div>
          </div>

          <div className='relative'>
            <img className='object-cover min-h-[400px] w-full max-h-[500px]' src={BannerOne} alt={``}/>
        
            <div className='container absolute grid -translate-x-1/2 -translate-y-1/2 lg:grid-cols-5 top-1/2 left-2/4'>
                <div className='lg:col-span-3'>
                    <h1 className='text-3xl font-bold uppercase lg:text-5xl md:text-4xl text-slate-800'>
                      <span className='block text-indigo-500'>SOFTGEMS </span>
                    </h1>
                    <p className='pt-5 text-base font-semibold'>
                    At Softgems, we understand that building a great team is key to achieving success in any business. That's why we offer top-notch staff augmentation services to help you quickly scale up your team with the right talent.
                    </p>
                    <p className='pt-5 text-base font-semibold'>
                    Our process starts with a deep understanding of your specific business needs, so we can identify the ideal candidates to fit seamlessly into your existing team. We then provide a rigorous screening and interview process to ensure that our candidates not only have the right skills and experience but also align with your company culture and values.
                    </p>
                    <p className='pt-5 text-base font-semibold'>
                    Once we've identified the perfect fit, our team handles all the logistics of bringing them onboard, including managing payroll, benefits, and other administrative tasks. And because we're committed to providing excellent service, we continue to monitor our candidates' performance and provide ongoing support to ensure your team is running smoothly.
                    </p>
                    
                    {/* <Link className='inline-block px-6 py-2.5 mt-10 font-medium text-white uppercase rounded bg-gradient-to-r from-pink-500 to-indigo-500 hover:from-purple-500 hover:to-indigo-500'>
                      READ MORE
                    </Link> */}

                </div>
            </div>
          </div>
        </Slider>
      </section>

      <div id='services'></div>

      {/* <section className='relative z-10 py-10 lg:py-20'>
        <div className='container grid gap-10 lg:grid-cols-2'>
            <div className='lg:pr-10'>
              <img className='rounded-xl' src={ImageOne} alt={``}/>
            </div>
            <div className=''>
                <p className='pt-5 font-medium text-sx'>eMetaComm</p>
                <h1 className='text-3xl font-bold uppercase lg:text-5xl md:text-4xl text-slate-800'>
                  A Metaverse Ready 
                  <span className='block text-indigo-500'>E-Commerce </span>
                </h1>
                <p className='pt-6 font-light'>
                  The e-MetaComm is India's 1st Metaverse ready product, which uses latest AR/VR Technology over full-stack technology solutions to the stakeholders. e-MetaComm will transform the mode of ecommerce from offline to online models. With the incorporation of VR and AR technologies, we have moved one step closer to the design of the metaverse.
                </p>
                <Link className='inline-block px-6 py-2.5 mt-12 font-medium text-white uppercase rounded bg-gradient-to-r from-pink-500 to-indigo-500 hover:from-purple-500 hover:to-indigo-500'>
                  READ MORE
                </Link>

            </div>
        </div>
      </section> */}

      <section className='pt-10 pb-12 mt-10 lg:pt-20 lg:pb-28 md:pb-20'>
        <div className='container'>
          {/* <p className='font-medium text-center text-sx'>eMetaComm</p> */}
          <h1 className='py-2 text-3xl font-bold text-center text-white uppercase bg-indigo-500 lg:text-4xl md:text-4xl'>
            Our <span className=''> Services</span>
          </h1>
          </div>
          
          {/* <div className='grid items-start gap-5 pt-12 lg:grid-cols-4 md:grid-cols-2'>
            <Disclosure as="div" className={`bg-white rounded-lg px-5 py-6`}>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex flex-col justify-between w-full text-base font-medium text-left text-slate-600">
                    <div className='w-12 h-12 p-3 mb-3 border border-indigo-500 rounded-full'>
                      <img className='rounded-xl' src={IconOne} alt={``}/>
                    </div>
                    <p>Product & platform engineering </p>
                    
                  </Disclosure.Button>
                  <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500 ">
                    Engineering next-generation software products and platforms at breakneck speed with our 
                    software product engineering DNA.
                  </Disclosure.Panel>
                  <Disclosure.Button className="inline-flex items-center justify-between py-0.5 px-2.5 mt-5 text-xl font-medium text-left border rounded-lg hover:bg-slate-50 hover:text-slate-600 text-slate-400">
                    {open ?(
                      <span className=''>-</span>
                    ):(
                      <span>+</span>
                    )}
                  </Disclosure.Button>
                </>
              )}
            </Disclosure>

            <Disclosure as="div" className={`bg-white rounded-lg px-5 py-6`}>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex flex-col justify-between w-full text-base font-medium text-left text-slate-600">
                    <div className='w-12 h-12 p-3 mb-3 border border-indigo-500 rounded-full'>
                      <img className='rounded-xl' src={IconTwo} alt={``}/>
                    </div>
                    <p>Data Services </p>
                    
                  </Disclosure.Button>
                  <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500 ">
                    One of the main obstacles for many companies is their own IT. A data and analytics strategy 
                    is not unlocked by legacy systems,
                  </Disclosure.Panel>
                  <Disclosure.Button className="inline-flex items-center justify-between py-0.5 px-2.5 mt-5 text-xl font-medium text-left border rounded-lg hover:bg-slate-50 hover:text-slate-600 text-slate-400">
                    {open ?(
                      <span className=''>-</span>
                    ):(
                      <span>+</span>
                    )}
                  </Disclosure.Button>
                </>
              )}
            </Disclosure>

            <Disclosure as="div" className={`bg-white rounded-lg px-4 py-6`}>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex flex-col justify-between w-full text-base font-medium text-left text-slate-600">
                    <div className='w-12 h-12 p-3 mb-3 border border-indigo-500 rounded-full'>
                      <img className='rounded-xl' src={IconThree} alt={``}/>
                    </div>
                    <p>Cloud engineering</p>
                    
                  </Disclosure.Button>
                  <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500 ">
                  Re-platform, re-host, and re-engineer your application from legacy monoliths to the cloud and leverage 
                  the cloud’s capability to solve complex business problems.
                  </Disclosure.Panel>
                  <Disclosure.Button className="inline-flex items-center justify-between py-0.5 px-2.5 mt-5 text-xl font-medium text-left border rounded-lg hover:bg-slate-50 hover:text-slate-600 text-slate-400">
                    {open ?(
                      <span className=''>-</span>
                    ):(
                      <span>+</span>
                    )}
                  </Disclosure.Button>
                </>
              )}
            </Disclosure>

            <Disclosure as="div" className={`bg-white rounded-lg px-4 py-6`}>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex flex-col justify-between w-full text-base font-medium text-left text-slate-600">
                    <div className='w-12 h-12 p-3 mb-3 border border-indigo-500 rounded-full'>
                      <img className='rounded-xl' src={IconFour} alt={``}/>
                    </div>
                    <p>Security services</p>
                    
                  </Disclosure.Button>
                  <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500 ">
                    Make your data and application readily available to authorized 
                    users with enhanced security controls.
                  </Disclosure.Panel>
                  <Disclosure.Button className="inline-flex items-center justify-between py-0.5 px-2.5 mt-5 text-xl font-medium text-left border rounded-lg hover:bg-slate-50 hover:text-slate-600 text-slate-400">
                    {open ?(
                      <span className=''>-</span>
                    ):(
                      <span>+</span>
                    )}
                  </Disclosure.Button>
                </>
              )}
            </Disclosure>

            <Disclosure as="div" className={`bg-white rounded-lg px-4 py-6`}>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex flex-col justify-between w-full text-base font-medium text-left text-slate-600">
                    <div className='w-12 h-12 p-3 mb-3 border border-indigo-500 rounded-full'>
                      <img className='rounded-xl' src={IconFive} alt={``}/>
                    </div>
                    <p>Payment Consulting</p>
                    
                  </Disclosure.Button>
                  <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500 ">
                    PayCube strongly believes in putting the requirements in a well-documented format, this goes a 
                    long way in bringing different stakeholders on the same page.
                  </Disclosure.Panel>
                  <Disclosure.Button className="inline-flex items-center justify-between py-0.5 px-2.5 mt-5 text-xl font-medium text-left border rounded-lg hover:bg-slate-50 hover:text-slate-600 text-slate-400">
                    {open ?(
                      <span className=''>-</span>
                    ):(
                      <span>+</span>
                    )}
                  </Disclosure.Button>
                </>
              )}
            </Disclosure>

            <Disclosure as="div" className={`bg-white rounded-lg px-4 py-6`}>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex flex-col justify-between w-full text-base font-medium text-left text-slate-600">
                    <div className='w-12 h-12 p-3 mb-3 border border-indigo-500 rounded-full'>
                      <img className='rounded-xl' src={IconSix} alt={``}/>
                    </div>
                    <p>Staff Augmentation</p>
                    
                  </Disclosure.Button>
                  <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500 ">
                  IT Skills are diverse and the nature of technology initiatives can warrant the need for 
                  short or long term skills which might not exist within a customer’s current environment.
                  </Disclosure.Panel>
                  <Disclosure.Button className="inline-flex items-center justify-between py-0.5 px-2.5 mt-5 text-xl font-medium text-left border rounded-lg hover:bg-slate-50 hover:text-slate-600 text-slate-400">
                    {open ?(
                      <span className=''>-</span>
                    ):(
                      <span>+</span>
                    )}
                  </Disclosure.Button>
                </>
              )}
            </Disclosure>

            <Disclosure as="div" className={`bg-white rounded-lg px-4 py-6`}>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex flex-col justify-between w-full text-base font-medium text-left text-slate-600">
                    <div className='w-12 h-12 p-3 mb-3 border border-indigo-500 rounded-full'>
                      <img className='rounded-xl' src={IconSaven} alt={``}/>
                    </div>
                    <p>Blockchain</p>
                    
                  </Disclosure.Button>
                  <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500 ">
                  Innowise Group reinforces businesses with world-class blockchain solutions. We draw upon the profound 
                  knowledge of blockchain to automate, secure and accelerate operations. 
                  </Disclosure.Panel>
                  <Disclosure.Button className="inline-flex items-center justify-between py-0.5 px-2.5 mt-5 text-xl font-medium text-left border rounded-lg hover:bg-slate-50 hover:text-slate-600 text-slate-400">
                    {open ?(
                      <span className=''>-</span>
                    ):(
                      <span>+</span>
                    )}
                  </Disclosure.Button>
                </>
              )}
            </Disclosure>
           
          </div> */}


          <section className='py-10 lg:py-20'>
            <div className='container'>
                <div className='grid gap-5 lg:grid-cols-3 gap-x-20'>
                    <div className='lg:col-span-2'>
                        {/* <p className='pb-5 text-3xl font-semibold lg:text-4xl text-slate-800'>Product & Platform <span className='inline-block text-indigo-500 lg:block'>Engineering</span></p> */}

                        <p className='pb-5 text-3xl font-semibold lg:text-4xl text-slate-800 '>Product & Platform <span className='text-indigo-500'>Engineering</span> </p>
                        
                        <p className='pb-4 text-base font-semibold  leading-6 '>
                          
                        We have a software product engineering DNA that enables us to build cutting-edge software products and platforms quickly.
                        </p>
                        <p className='pb-4 text-base font-semibold leading-6'>                          
                            <span className='font-bold text-lg underline'style={{ color: 'rgb(99, 102, 241)' }}><svg class="w-6 h-6 dark:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 14l9-5-9-5-9 5 9 5z"></path><path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"></path></svg>Microservices:</span> Microservices architecture is a development approach that allows the different components of an application to function independently yet work in collaboration. It offers benefits such as enhanced scalability, streamlined coding, and faster deployment while facilitating the shift from traditional monolithic systems.
                        </p>
                        <p className='pb-4 text-base font-semibold leading-6'>
                        This approach also enables the provision of self-service installations for both infrastructure and applications, making application operations more manageable during production. The result is a reduction in complexity and time to market for businesses.
                        </p>
                        {!serOne && (<button onClick={() => setSerOne(serOne => !serOne)} className='text-left text-indigo-500'>{serOne ? 'Read Less':'Read More...'}</button>)}
                        
                        
                    </div>

                    <div className=''>
                        <img className='' src={servicesOne} alt={``}/>
                    </div>
                    {serOne && (
                    <div className='col-span-full'>

                        <p className='pb-4 text-base font-semibold leading-6'>
                          <span className='font-bold text-lg underline 'style={{ color: 'rgb(99, 102, 241)' }}><svg class="w-6 h-6 dark:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"></path></svg>Full-stack product engineering: </span> 
                          Our expertise lies in defining, implementing, and managing software systems and their infrastructure and data centers. We specialize in architecting efficient, reusable cloud-native application systems using the latest front-end and back-end technologies. Our focus is on building intelligent systems supported by AI and big data technology, using modern and innovative architectural approaches.
                        </p>
                     
                        <p className='pb-4 text-base font-semibold leading-6'>
                          <span className='font-bold text-lg underline 'style={{ color: 'rgb(99, 102, 241)' }}><svg class="w-6 h-6 dark:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"></path></svg>Emerging technology: </span> 
                          We integrate agile and continuous technologies in automation, cloud, and containers with emerging technologies like data science, AI, and blockchain.
                        </p>

                        <p className='pb-4 text-base font-semibold leading-6'>
                          <span className='font-bold text-lg underline 'style={{ color: 'rgb(99, 102, 241)' }}><svg class="w-6 h-6 dark:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"></path></svg>CloudOps: </span> 
                          Cloud operations such as migration, deployment, and orchestration of single and multi-cloud environments can be made easier with increased flexibility and scalability. By implementing CloudOps, you can accelerate automation, improve security, enhance RPO/RTO, achieve on-demand scalability, and increase overall IT cost savings.
                        </p>

                        <div className='grid gap-12 md:pt-5 md:grid-cols-3'>
                        <p className='pb-4 text-base font-semibold leading-6'>
                            <span className='font-bold text-lg underline'style={{ color: 'rgb(99, 102, 241)' }}><svg class="w-6 h-6 dark:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"></path></svg>Devops: </span> 
                            Facilitate smoother communication and collaboration between development and operations teams, while also building development pipelines that support continuous integration and reliable, hassle-free software delivery.</p>

                          <p className='pb-4 text-base font-semibold leading-6'>
                            <span className='font-bold text-lg underline'style={{ color: 'rgb(99, 102, 241)' }}><svg class="w-6 h-6 dark:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"></path></svg>TestOps: </span> 
                            Our approach involves designing, developing, and deploying tests in lockstep with DevOps practices to ensure the delivery of high-quality and reliable software throughout the product lifecycle.
                          </p>

                          <p className='pb-4 text-base font-semibold leading-6'>
                            <span className='font-bold text-lg underline'style={{ color: 'rgb(99, 102, 241)' }}><svg class="w-6 h-6 dark:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"></path></svg>ContainerOps: </span> 
                            With our approach, you can build, test, deploy, and redeploy applications across multiple environments using best practices and operational plans. This helps accelerate application development and drive digital transformation.</p>
                        </div>
                       
                    </div>
                    )}
                    {serOne && (<button onClick={() => setSerOne(serOne => !serOne)} className='text-left text-indigo-500'>{serOne ? 'Read Less':'Read More..'}</button>)}
                </div>
            </div>
        </section>

        <section className='py-10 text-white bg-indigo-500 lg:py-20'>
            <div className='container'>
                <div className='grid gap-5 lg:grid-cols-3 gap-x-20'>
                    <div>
                        <img className='' src={servicesTwo} alt={``}/>
                    </div>
                    <div className='lg:col-span-2'>
                      
                        <p className='pb-5 text-3xl font-semibold lg:text-4xl '>Data  <span>Services</span> </p>
                        
                        <p className='pb-4 text-base font-semibold leading-6'>
                        Legacy systems, fragmented technology, and localized data that is difficult to share are major obstacles for many companies when it comes to implementing a data and analytics strategy. Rather than unlocking the potential of their data, these factors actually hinder progress. With the fast-paced nature of modern business, delaying the implementation of a new IT infrastructure for years is not a viable option. Doing nothing is not an option either, as it can have severe consequences for a business.
                        </p>
                        <p className='pb-4 text-base font-semibold leading-6'>
                        Our approach involves developing a data and digital platform that accelerates data-driven transformation. By decoupling data from legacy systems, it becomes easily accessible for new applications and business models, serving as a launchpad for data and analytics. With this platform, companies can start small and grow quickly without being constrained by their IT infrastructure or the rate of its replacement. This allows them to conceive large ideas and scale up rapidly.</p>
                        {!serTwo && (<button onClick={() => setSerTwo(serTwo => !serTwo)} className='text-left'>{serTwo ? 'Read Less':'Read More...'}</button>)}
                    </div>

                        {serTwo && (
                          <div className='col-span-full'>
                              <p className='pb-4 text-base font-semibold leading-6'>
                                <span className='font-bold text-lg underline'>Data Governance:</span> Our services include data governance, which comprises a set of guidelines and procedures that ensure high data quality throughout its entire lifecycle. We carefully vet and arrange all of your data so that it is ready for you to explore and gain valuable insights.
                              </p>
                              
                              <p className='pb-4 text-base font-semibold leading-6'>Implementing data governance results in better, leaner, and cleaner data, leading to improved analytics, better business decisions, and ultimately better business results. This, in turn, can lead to a stronger position in the market, increased reputation, and higher profit margins. It is essential to implement data governance properly to achieve these benefits.</p>
                            
                              <p className='pb-4 text-base font-semibold leading-6'>
                                <span className='font-bold text-lg underline'>Data Consulting:</span> We recognize that every business has its own unique challenges, whether it's struggling to fully transition to the cloud or seeking a competitive edge through multi-touch attribution driven by Machine Learning. Our industry-leading experts understand these challenges and are equipped to provide the right solutions to drive meaningful change. We empower our clients to develop and extend their own capabilities by combining data and analytics consulting teams with specialized skills, ensuring that they have the necessary tools to succeed.
                                </p>
                                <p className='pb-4 text-base font-semibold leading-6'>
                                <span className='font-bold text-lg underline'>Data Engineering:
                                </span> To navigate the path of data and analytics successfully, a comprehensive set of skills is required. Our data and analytics strategy work stands out because we leverage a global network of industry, functional, and technical specialists with expertise in diverse areas. This enables us to provide our clients with the necessary guidance and support to achieve their goals in the world of data and analytics.</p>
                                <p className='pb-4 text-base font-semibold leading-6'>
                                <span className='font-bold text-lg underline'>AI/ML Ecosystem:</span> Data science involves tasks such as pre-processing, analysis, prediction, and visualization of data, while AI and ML refer to the implementation of various predictive models that help in anticipating data-based events.

                                AI analytics and Machine learning tools are capable of automating routine tasks such as deleting redundant data, filling dataset gaps, and alerting human engineers about anomalies. These algorithms can assist dedicated data engineers as they tackle complex problems that will ultimately lead to greater returns for the organization, by handling labor-intensive tasks that humans may not prefer to undertake. Our wide range of services that help you to level up your business that includes,
                                </p>
                                <ul className='list-items text-base font-semibold '>
                        <li>“Data Exploratory Analysis"</li>
                        <li>“Data Preparation"</li>
                        <li>“Analytical Modelling"</li>
                        <li>“MLOps"</li>
                      </ul>
                                <p className='pb-4 text-base font-semibold leading-6'>
                                <span className='font-bold text-lg underline'>New Data lakes:</span> We comprehend the specific needs and technology stack of your organization, and augment your core team to accelerate project delivery and success. Through a deep understanding of your business, we deliver tailored solutions that empower your business growth.

                                A data lake is a centralized repository for storing large volumes of raw, unprocessed data from various sources. This storage system accommodates structured, semi-structured, and unstructured data in a flexible format for future analysis. Data is associated with metadata tags and identifiers, facilitating faster and more efficient retrieval.Our tailor-made services include,
                                </p>
                                <ul className='list-items text-base font-semibold '>
                        <li>“Big Data Integration"</li>
                        <li>“Data Warehouse"</li>
                        <li>“Data Lake builds on the cloud"</li>
                        <li>“Data Migration to Cloud"</li>
                      </ul>
                            </div>
                        )}
                        {serTwo && (
                          <button onClick={() => setSerTwo(serTwo => !serTwo)} className='text-left'>{serTwo ? 'Read Less':'Read More..'}</button>
                        )}
                        
                    
                </div>
            </div>
        </section>

       

        <section className='py-10 lg:py-20'>
            <div className='container'>
                <div className='grid gap-5 lg:grid-cols-3 gap-x-20'>
                    <div className='lg:col-span-2'>
                        <p className='pb-5 text-3xl font-semibold lg:text-4xl text-slate-800'>Cloud <span className='text-indigo-500'>Engineering</span> </p>
                        <p className='pb-4 text-base font-semibold leading-6'>
                        Leverage the power of the cloud to solve complex business problems by migrating your legacy monolith applications to the cloud through re-platforming, re-hosting, and re-engineering.</p>
                        <p className='pb-4 text-base font-semibold leading-6'>
                            <span className='font-bold text-lg underline'style={{ color: 'rgb(99, 102, 241)' }}>Serverless: </span> 
                            Empower your developers to focus on creating and deploying services without having to deal with the underlying infrastructure. Cloud providers offer servers to operate applications, databases, and storage systems of any size, while developers can build and deploy code.
                        </p>

                        <p className='pb-4 text-base font-semibold leading-6'>
                            <span className='font-bold text-lg underline'style={{ color: 'rgb(99, 102, 241)' }}>Application Modernization: </span> 
                            Why rely on outdated monolithic legacy systems when modernizing your applications can help you adapt to the latest digital industry standards?</p>
                        
                        
                        {!serThree && (<button onClick={() => setSerThree(serThree => !serThree)} className='text-left text-indigo-500'>{serThree ? 'Read Less':'Read More...'}</button>)}
                    </div>

                    <div>
                        <img className='' src={servicesThree} alt={``}/>
                    </div>
                    {serThree && (
                    <div className='col-span-full'>
                      
                      <div className='grid gap-12 md:pt-5 md:grid-cols-3'>
                        <p className='pb-4 text-base font-semibold leading-6'>
                            <span className='font-bold text-lg underline'style={{ color: 'rgb(99, 102, 241)' }}><svg class="w-6 h-6 dark:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"></path></svg>Assess and Migrate: </span> 
                            Modernize your applications by moving away from legacy monoliths and migrating to the cloud. Utilize the full potential of the cloud to solve complex business challenges through re-platforming, re-hosting, and re-engineering your applications. The Lift and Shift approach can help you easily migrate all of your existing code to the cloud and take advantage of the cost-efficient cloud infrastructure.</p>
                          <p className='pb-4 text-base font-semibold leading-6'>
                            <span className='font-bold text-lg underline'style={{ color: 'rgb(99, 102, 241)' }}><svg class="w-6 h-6 dark:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"></path></svg>Modernize: </span> 
                            We have a step-by-step modernization approach that ensures that your application availability, scalability, and security are improved gradually while minimizing business risks. Our Cloud Architect will use in-house and open-source tools to restructure the application architecture. Our engineers will turn the application components into containerized microservices using agile and DevOps methodologies.
                          </p>

                          <p className='pb-4 text-base font-semibold leading-6'>
                            <span className='font-bold text-lg underline'style={{ color: 'rgb(99, 102, 241)' }}><svg class="w-6 h-6 dark:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"></path></svg>Optimize: </span> 
                            That's correct! By leveraging the cloud-native approach, it becomes easier to monitor and manage applications, as well as maintain high availability and performance. DevOps principles such as continuous integration and delivery also help to streamline the deployment process, ensuring that any changes are deployed quickly and reliably. By adopting these practices, organizations can stay competitive and respond more rapidly to changing business needs.</p>
                        </div>

                      <p className='pb-4 text-base font-semibold leading-6'>
                            <span className='font-bold text-lg underline'style={{ color: 'rgb(99, 102, 241)' }}>Continued Integration and Delivery: </span> 
                            That's correct! By automating Continuous Integration (CI) and Continuous Deployment/Delivery (CD), you can accelerate the development and delivery process, reduce manual errors, and improve code quality and security. This can be achieved by implementing tools and processes that automate the building, testing, and deployment of code changes. It is also important to optimize the pipeline environment, such as by using scalable infrastructure and monitoring tools to identify and fix bottlenecks in the pipeline.
                        </p>

                        <p className='pb-4 text-base font-semibold leading-6'>
                            <span className='font-bold text-lg underline'style={{ color: 'rgb(99, 102, 241)' }}>Containerization: </span> 
                            Using containers, an application can be packaged with its dependencies and libraries, making it highly portable and allowing it to run on any platform and infrastructure that supports containerization. This also allows for easy scaling of the application by simply copying and deploying container instances on the cloud as demand increases. Furthermore, containers provide isolation between applications, allowing flaws and problems in one container to be isolated and addressed without affecting other containers, simplifying maintenance, development, and troubleshooting processes.
                        </p>
                        <p className='pb-4 text-base font-semibold leading-6'>
                            <span className='font-bold text-lg underline'style={{ color: 'rgb(99, 102, 241)' }}>Security: </span> 
                            Ensure your authorized users have seamless access to your data and applications, while implementing advanced security controls that prevent any data leaks. This includes incorporating strong authentication systems, data encryption, visibility and threat detection mechanisms, as well as ensuring continuous compliance with relevant security regulations. By integrating security at every stage, you can provide comprehensive protection for your data and applications.
                        </p>
                    </div>
                    )}

                    {serThree && (<button onClick={() => setSerThree(serThree => !serThree)} className='text-left text-indigo-500'>{serThree ? 'Read Less':'Read More...'}</button>)}
                </div>
            </div>
        </section>

        <section className='py-10 text-white bg-indigo-500 lg:py-20'>
            <div className='container'>
                <div className='grid gap-5 lg:grid-cols-3 gap-x-20'>
                    

                    <div>
                        <img className='' src={servicesFour} alt={``}/>
                    </div>
                    <div className='col-span-2'>
                        <p className='pb-5 text-3xl font-semibold lg:text-4xl'>Security <span > Services</span> </p>
                        <p className='pb-4 text-base font-semibold leading-6'>
                        Ensure secure access to your data and application for authorized users through the implementation of advanced security measures.
                        </p>
                        <p className='pb-4 text-base font-semibold leading-6'>
                            <span className='font-bold text-lg underline'>Cybersecurity challenges demand an organization to face two facts: </span> 
                            First, the protection of critical company and customer information which is a business requirement to protect the company’s reputation and enterprise value.

                            Second, even the best programs will experience failure and expose some information the company would like to protect.
                        </p>
                        <p className='pb-4 text-base font-semibold leading-6'>
                        At our company, we understand that confidence in security and privacy cannot be achieved simply by assuming that nothing will happen. Instead, we believe that true confidence comes from being aware of all the possible risks and developing both proactive and reactive solutions to address them.
                        </p>
                        
                        
                        {!serFour && (<button onClick={() => setSerFour(serFour => !serFour)} className='text-left'>{serFour ? 'Read Less':'Read More...'}</button>)}
                    </div>
                      {serFour && (

                      
                    <div className='col-span-full'>
                      <p className='pb-4 text-base font-semibold leading-6'>
                      A holistic approach involves identifying your business's top priorities, such as meeting regulatory requirements like PCI DSS, SOC 1, or HIPAA. From there, we develop and support a comprehensive security program to ensure your business is designed to grow securely.
                        </p>
                        <ul className='list-items text-base font-semibold '>
                        <li>Identity & Access Management</li>
                        <li>Vulnerability Assessment & Penetration Testing</li>
                          <li>Infrastructure Security</li>
                          <li>Endpoint Security</li>
                          <li>Security Operations Center</li>
                          <li>GRC Implementation</li>
                          <li>PCI Complaince</li>
                      </ul>

                        {/* <ul className='pl-5 space-y-3 list-disc'>
                          <li>Identity & Access Management</li>
                          <li>Vulnerability Assessment & Penetration Testing</li>
                          <li>Infrastructure Security</li>
                          <li>Endpoint Security</li>
                          <li>Security Operations Center</li>
                          <li>GRC Implementation</li>
                        </ul> */}


                    </div>
                    )}

                    {serFour && (<button onClick={() => setSerFour(serFour => !serFour)} className='text-left'>{serFour ? 'Read Less':'Read More...'}</button>)}
                </div>
            </div>
        </section>


        <section className='py-10 lg:py-20 '>
            <div className='container'>
                <div className='grid gap-5 lg:grid-cols-3 gap-x-20'>
                    <div className='lg:col-span-2'>
                        <p className='pb-5 text-3xl font-semibold lg:text-4xl text-slate-800'>Payment  <span className='text-indigo-500'>Consulting</span> </p>
                        <p className='pb-4 text-base font-semibold leading-6'>
                        Our technology consulting practice is a crucial first step towards initiating a wider technology project. Whether it is to drive innovation on legacy infrastructures, alleviate operational challenges, manage risks from outsourced application development, or bridge gaps between people, processes, and technology within customer organizations, we have the expertise to support you. We recognize the current state of payment technology initiatives in the retail and payment markets and the difficulties they face. Therefore, we understand the necessity for a payments technology practice that is not only proficient in software development but is also capable of engaging with customers even before the software development process begins. Only an organization that comprehends the business can provide a complete 360-degree view of software engineering for payments.
                        </p>
                        {!serFive && (<button onClick={() => setSerFive(serFive => !serFive)} className='text-left text-indigo-500'>{serFive ? 'Read Less':'Read More...'}</button>)}
                    </div>

                    <div>
                        <img className='' src={servicesFive} alt={``}/>
                    </div>
                    {serFive && (
                    <div className='col-span-full'>
                      <p className='pb-4 text-base font-semibold leading-6'>
                          <span className='font-bold text-lg underline'style={{ color: 'rgb(99, 102, 241)' }}>Technology Strategy Consulting: </span> 
                          That's correct. Payment systems are critical infrastructure and any disruption or failure in these systems can have a significant impact on businesses, their customers, and the wider economy. It is essential to have robust and resilient systems in place that can ensure the smooth operation of payment systems and minimize the risk of disruption or failure. We have increasingly seen our customers ask us these questions:
                      </p>
                      <ul className='list-items text-base font-semibold '>
                        <li>“How do we introduce a new payment instrument (card, wallet, etc.) in to my portfolio without causing much disruption to the existing infrastructure?"</li>
                        <li>“We want to issue our loyalty or gift card program? What is the technical impact of doing this?"</li>
                        <li>“We are an ISO for a processor, but now we want to take transactions in house and provide value added services- What technology stack should I build?"</li>
                        <li>“Market is full of products that support transaction processing. Which of these products is suitable for us? Or should we build one?"</li>
                        <li>“We have floated an RFP for processing solutions. How do we evaluate the technology from these competing bids?"</li>
                      </ul>
                      <p className='pb-4 text-base font-semibold leading-6'>
                      Our Strategy Consulting practice offers assistance to customers who face challenges such as disruptions to their payments systems. We leverage our domain experience, technical background, and implementation capabilities to help customers define a roadmap for their payment-related technology infrastructure and related buildouts.
                      </p>
                    </div>
                    )}
                    {serFive && (<button onClick={() => setSerFive(serFive => !serFive)} className='text-left text-indigo-500'>{serFive ? 'Read Less':'Read More...'}</button>)}
                </div>
            </div>
        </section>

        <section className='py-10 text-white bg-indigo-500 lg:py-20'>
            <div className='container'>
                <div className='grid gap-5 lg:grid-cols-3 gap-x-20'>
                    
                    <div>
                      <img className='' src={servicesSix} alt={``}/>
                    </div>
                    <div className='lg:col-span-2'>
                        <p className='pb-5 text-3xl font-semibold lg:text-4xl '>Staff   Augmentation </p>
                        <p className='pb-4 text-base font-semibold leading-6'>
                        IT skills are varied and technology initiatives may require short-term or long-term skills that may not be available in a customer's current environment.
                        </p>
                        <p className='pb-4 text-base font-semibold leading-6'>
                        We collaborates closely with its clients to bridge the gap in terms of staff augmentation and contingent workforce management, utilizing its domain expertise and ability to mobilize resources quickly.
                        </p>

                        
                        {!serSix && (<button onClick={() => setSerSix(serSix => !serSix)} className='text-left'>{serSix ? 'Read Less':'Read More...'}</button>)}
                        
                    </div>
                    {serSix && (
                    <div className='col-span-full'>

                      <div className='grid gap-12 md:pt-5 md:grid-cols-3'>
                        <ul className='list-items'>
                            <li>
                            Rigorous intake process by our highly skilled technical team
                            </li>
                            <li>
                            The consultants supported by the larger technical team
                            </li>
                            <li>
                            The consultants work in US overlapping hours (open for negotiation)
                            </li>
                            <li>
                            Detailed background check (being a payments company, it's a must)
                            </li>
                        </ul>

                        <ul className='list-items'>
                            <li>
                              Individual Communication
                            </li>
                            <li>
                              Employee Wellness Program
                            </li>
                            <li>
                              Employee Benefits
                            </li>
                            <li>
                              Overall Career Development (Mentoring, Soft skill Development & Knowledge transfer)
                            </li>
                            <li>
                              Equity participation/ Profit Sharing
                            </li>
                        


                        <p className='pb-4 text-sm font-light leading-6'>
                        </p>
                        </ul>
                      </div>

                    </div>
                    )}
                    {serSix && (<button onClick={() => setSerSix(serSix => !serSix)} className='text-left'>{serSix ? 'Read Less':'Read More...'}</button>)}
                </div>
            </div>
        </section>
        


        <section className='pt-10 lg:pt-20'>
            <div className='container'>
                <div className='grid gap-5 lg:grid-cols-3 gap-x-20'>
                    <div className='lg:col-span-2'>
                        <p className='pb-5 text-3xl font-semibold lg:text-4xl text-slate-800'>Blockchain </p>
                        <p className='pb-4 text-base font-semibold leading-6'>
                        Our company provides cutting-edge blockchain solutions to businesses, leveraging our extensive expertise in blockchain technology to streamline operations and enhance security. We work closely with our clients to guide them through the process of implementing the latest technological innovations to optimize their operations at every stage.
                        </p>
                        <p className='pb-4 text-base font-semibold leading-6'>
                            <span className='font-bold text-lg underline'style={{ color: 'rgb(99, 102, 241)' }}>Smart contracts :</span> Our skilled team of blockchain developers can create and test customized decentralized solutions that automate data transactions and eliminate paperwork to improve the security and reduce costs of transaction-based processes. By utilizing blockchain smart contracts, we can optimize the efficiency and speed of these processes, while enhancing security through the use of cryptographic methods.
                        </p>
                        {!serSaven && (<button onClick={() => setSerSaven(serSaven => !serSaven)} className='text-left text-indigo-500'>{serSaven ? 'Read Less':'Read More...'}</button>)}
                    </div>

                    <div>
                        <img className='' src={servicesSaven} alt={``}/>
                    </div>
                    {serSaven && (
                    <div className='col-span-full'>
                        <p className='pb-4 text-base font-semibold leading-6'>
                            <span className='font-bold text-lg underline'style={{ color: 'rgb(99, 102, 241)' }}>Decentralized applications (DApps) :</span> Our innovative approach combines the effectiveness of smart contracts with a user-friendly interface to develop a decentralized peer-to-peer network. This network is designed to eliminate the potential point of failure caused by a centralized hosting server, thereby preventing downtime and operational restrictions. This approach ensures that the network operates smoothly and reliably, with data being stored across a distributed network of nodes, which results in a highly efficient and accessible network for users.
                        </p>
                        <p className='pb-4 text-base font-semibold leading-6'>
                            <span className='font-bold text-lg underline'style={{ color: 'rgb(99, 102, 241)' }}>CEX/DEX platforms :</span> No matter whether you are planning to develop a centralized or decentralized platform for cryptocurrency exchange, we can provide end-to-end services. Our team can handle everything from UI design to implementation of specialized features, all while adhering to the highest security and KYC standards in the world.
                        </p>

                        <p className='pb-4 text-base font-semibold leading-6'>
                            <span className='font-bold text-lg underline'style={{ color: 'rgb(99, 102, 241)' }}>Tokenomics:</span> Our team of business analysts and consultants have a wealth of experience that they can draw upon to evaluate the potential value of the tokens you plan to issue. They will conduct a thorough analysis of the market and unique features of your token to help you select the most appropriate technology, utilization strategy, and supply volume for your cryptocurrency. This will help ensure that your token is optimized for success and positioned to deliver maximum value to your stakeholders.
                        </p>

                        <p className='pb-4 text-base font-semibold leading-6'>
                            <span className='font-bold text-lg underline'style={{ color: 'rgb(99, 102, 241)' }}>Decentralized file transfer platforms:</span> Our team of engineers will leverage the InterPlanetary File System and the Hypercore protocol to construct a blockchain-based distributed file transfer platform that enables swift and secure sharing of files of any type or size among platform users.
                        </p>
                        
                        <p className='pb-4 text-base font-semibold leading-6'>
                            <span className='font-bold text-lg underline'style={{ color: 'rgb(99, 102, 241)' }}>Cryptocurrency Wallets:</span>Developing a secure, functional, and user-friendly cryptocurrency wallet is essential, regardless of whether it is custodial or non-custodial. With our team's expertise in blockchain, cryptocurrencies, and application development, we can create a top-quality cryptocurrency wallet for any platform quickly and efficiently.
                        </p>
                        <p className='pb-4 text-base font-semibold leading-6'>
                            <span className='font-bold text-lg underline'style={{ color: 'rgb(99, 102, 241)' }}>Blockchain-based security solutions:</span>Blockchain platforms are an ideal choice for ensuring both digital and physical security, with a variety of implementation options available. Our team of software developers can create any type of security system, from access control to data transfer encryption, using blockchain technology. The key advantage of these systems is their highly secure nature, which makes them nearly impossible to hack.
                        </p>
                        <p className='pb-4 text-base font-semibold leading-6'>
                            <span className='font-bold text-lg underline'style={{ color: 'rgb(99, 102, 241)' }}>ICO/IEO/IDO development:</span>The initial token offering is a critical stage in any new blockchain-based project, as it enables businesses to raise the necessary funds to achieve their goals. Our team of specialists can assist in every aspect of launching the project, from creating a roadmap and whitepaper to developing and publicly releasing tokens on any platform. With our help, businesses can ensure a seamless and successful launch of their blockchain-based project.
                        </p>
                        <p className='pb-4 text-base font-semibold leading-6'>
                            <span className='font-bold text-lg underline'style={{ color: 'rgb(99, 102, 241)' }}>NFT Asset design:</span>Our team of 2D and 3D design experts can create one-of-a-kind assets that can be used in any digital world, or an accurate digital replica of existing physical assets. These designs can be minted as NFT tokens, enabling ownership rights and providing a unique value proposition to buyers.
                        </p>
                        <p className='pb-4 text-base font-semibold leading-6'>
                            <span className='font-bold text-lg underline'style={{ color: 'rgb(99, 102, 241)' }}>NFT tokens minting:</span>The process of creating or minting NFT tokens can be complex and requires technical expertise. Our team of blockchain specialists can manage the entire process, from developing smart contracts to creating NFT tokens on the Ethereum blockchain.
                        </p>
                        <p className='pb-4 text-base font-semibold leading-6'>
                            <span className='font-bold text-lg underline'style={{ color: 'rgb(99, 102, 241)' }}>Escrow/arbitrage solutions development:</span>Placing an NFT token on an auction necessitates a solution that can securely and automatically handle all bidders' tokens. Our team of blockchain experts can create smart contracts that are suitable for collecting bids, returning tokens to participants, and transferring assets to auction winners at the conclusion of the auction.
                        </p>
                    </div>
                    )}
                    {serSaven && (<button onClick={() => setSerSaven(serSaven => !serSaven)} className='text-left text-indigo-500'>{serSaven ? 'Read Less':'Read More...'}</button>)}
                </div>
            </div>
        </section>
        <section className='py-10 text-white bg-indigo-500 lg:py-20'>
            <div className='container'>
                <div className='grid gap-5 lg:grid-cols-3 gap-x-20'>
                    
                    <div>
                      <img className='' src={serviceEleven} alt={``}/>
                    </div>
                    <div className='lg:col-span-2'>
                        <p className='pb-5 text-3xl font-semibold lg:text-4xl '>AR/VR/MR development services </p>
                        
                        <p className='pb-4 text-base font-semibold leading-6'>
                        Our team specializes in developing cutting-edge marker-based and location-based AR applications for iOS, Android, and smart glasses, utilizing GPS data, custom markers, and visual SLAM inputs.
                        </p>
                        
                        

                        
                        {!serSix && (<button onClick={() => setSerSix(serSix => !serSix)} className='text-left'>{serSix ? 'Read Less':'Read More...'}</button>)}
                        
                    </div>
                    {serSix && (
                    <div className='col-span-full'>

                      <div className='grid gap-12 md:pt-5 md:grid-cols-3'>
                      <p className='pb-4 text-base font-semibold leading-6'>
                            <span className='font-bold text-lg underline'>Mixed reality software development :</span> We have brought together a team of professionals who are leading the way in the mixed reality market. Our team provides comprehensive turnkey MR solutions that cover all project stages, from concept development to solution launch and ongoing support.
                        </p>
                        <p className='pb-4 text-base font-semibold leading-6'>
                            <span className='font-bold text-lg underline'>Dedicated AR/VR developers :</span> We provide professional teams of AR/VR developers for hire who can work on projects of varying complexity. Our specialists continuously refine their skills to stay up-to-date with the latest technology advancements and market trends in AR/VR development.
                        </p>
                        <p className='pb-4 text-base font-semibold leading-6'>
                            <span className='font-bold text-lg underline'>AR/VR consulting :</span> Our experts conduct comprehensive market research and generate revenue forecasts to provide our customers with a clear understanding of technical and marketing prospects. We work closely with our customers to transform their ideas and requirements into specific product roadmaps.
                        </p>
                        <p className='pb-4 text-base font-semibold leading-6'>
                            <span className='font-bold text-lg underline'>3D design and modelling :</span> We specialize in creating customized 3D models for game development, NFT marketplaces, marketing campaigns, and more. Our highly skilled in-house 3D designers work closely with other departments to provide top-quality 3D data visualization services.
                        </p>
                        <p className='pb-4 text-base font-semibold leading-6'>
                            <span className='font-bold text-lg underline'>Support and maintenance :</span> We offer multi-layer support for AR/VR solutions, adapting to the evolving technology and unique business requirements. Our team helps enhance your services, streamline maintenance costs, and extend the functionality of your applications.
                        </p>
                        <p className='pb-4 text-base font-semibold leading-6'>
                            <span className='font-bold text-lg underline'>VR/AR game development :</span> Our team specializes in developing immersive VR/AR games tailored to any platform. Our experienced developers draw on their expertise in game development as well as VR/AR technology to deliver games with unparalleled levels of immersion.
                        </p>

                       
                      </div>

                    </div>
                    )}
                    {serSix && (<button onClick={() => setSerSix(serSix => !serSix)} className='text-left'>{serSix ? 'Read Less':'Read More...'}</button>)}
                </div>
            </div>
        </section>
        {/* </div> */}
      </section>


      {/* <section className='py-10 lg:py-20 marketing-section'>
          <div className='container grid lg:grid-cols-2'>
            <div className='p-5 py-10 text-white lg:col-start-2 lg:p-10 lg:py-16 marketing-box'>
              <p className='text-2xl lg:text-4xl md:text-3xl'>
                eMarketingCRM <br  className='hidden lg:block'/>
                Power of CRM and <br className='hidden lg:block'/>
                ERP Combined
              </p>
              <p className='pt-5 lg:pr-10'>
                An accurate view of customer information from the front end combined with resource 
                planning at the back end gives you control of your business like never before
              </p>

              <div className='pt-5'>
                <Link className='inline-block px-5 py-2 mt-3 mr-2 font-medium text-white uppercase border border-white rounded-lg hover:bg-white hover:text-indigo-500'>READ MORE</Link>
                <Link className='inline-block px-5 py-2 mt-3 font-medium text-indigo-500 uppercase bg-white border border-white rounded-lg hover:text-indigo-600'>Visit eMarketingCRM</Link>
              </div>
            </div>
          </div>
      </section> */}


      <section className='py-10 lg:py-24 md:py-20'>
        <div className='container'>
          {/* <p className='font-medium text-center text-sx'>Our MVP</p> */}
          <h1 className='text-3xl font-bold text-center uppercase lg:text-5xl md:text-4xl text-slate-800'>
          Our<span className='text-indigo-500 '>  MVP</span>
          </h1> 
          
          <Slider {...blogSettings} className={`lg:pt-14 pt-10`}>
            <div className='sm:px-5'>
              <div>
                <img className='object-cover w-full h-56 rounded-xl' src={BlogImageOne} alt={``}/>
              </div>
              <Link to={'https://youtu.be/7yjFsrVSijQ'} className="inline-block pt-3 text-lg font-semibold">Multichain NFT based Space Shooting play to win game</Link>
              <p className='pt-2 mt-2 text-sm font-light border-t'>
              India's first multichain NFT-based play-to-win platform is ready for demo.
              It is a hyper-casual space shooting game
              The player can play and earn reward point
              Redeem reward points in any available chain like Ethereum,polygon, Binance etc.
              Players can buy Space Jet and Ammo using their token as an NFT ownership.

              Please connect us to know more about the product.
              </p>

              <Link className='inline-block mt-4 font-medium text-indigo-500'>Read More</Link>
            </div>

            <div className='sm:px-5'>
              <div>
                <img className='object-cover w-full h-56 rounded-xl' src={BlogImageTwo} alt={``}/>
              </div>
              <Link to={'https://youtu.be/oH1lZnujK0A'} className="inline-block pt-3 text-lg font-semibold">AR/VR based hotel management application</Link>
              <p className='pt-2 mt-2 text-sm font-light border-t'>
              Salient feature
              1. Add your multiple rooms and their content (even VR and 360 degree for better representation)
              2. Manage your customer data for better future sales and get the advantage of AI-based advertising.
              3. Whole booking management for online and offline guests.
              4. Separate dashboard and workflow management for guests and service providers.
              4. Exclusive travel plan for guests.
              3. Multiple payment options are available.
              4. Take control full control of a secured, technologically advanced system with amazon cloud.
              Please connect us to know more about the product.
              </p>

              <Link className='inline-block mt-4 font-medium text-indigo-500'>Read More</Link>
            </div>

            <div className='sm:px-5'>
              <div>
                <img className='object-cover w-full h-56 rounded-xl' src={BlogImageThree} alt={``}/>
              </div>
              <Link to={''} className="inline-block pt-3 text-lg font-semibold">No Talking, only hardcore Blockchaining Part 2</Link>
              <p className='pt-2 mt-2 text-sm font-light border-t'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam
              </p>

              <Link className='inline-block mt-4 font-medium text-indigo-500'>Read More</Link>
            </div>

            <div className='sm:px-5'>
              <div>
                <img className='object-cover w-full h-56 rounded-xl' src={BlogImageOne} alt={``}/>
              </div>
              <Link to={''} className="inline-block pt-3 text-lg font-semibold">No Talking, only hardcore Blockchaining Part 2</Link>
              <p className='pt-2 mt-2 text-sm font-light border-t'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam
              </p>

              <Link className='inline-block mt-4 font-medium text-indigo-500'>Read More</Link>
            </div>

            <div className='sm:px-5'>
              <div>
                <img className='object-cover w-full h-56 rounded-xl' src={BlogImageTwo} alt={``}/>
              </div>
              <Link to={''} className="inline-block pt-3 text-lg font-semibold">No Talking, only hardcore Blockchaining Part 2</Link>
              <p className='pt-2 mt-2 text-sm font-light border-t'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam
              </p>

              <Link className='inline-block mt-4 font-medium text-indigo-500'>Read More</Link>
            </div>

            <div className='sm:px-5'>
              <div>
                <img className='object-cover w-full h-56 rounded-xl' src={BlogImageThree} alt={``}/>
              </div>
              <Link to={''} className="inline-block pt-3 text-lg font-semibold">No Talking, only hardcore Blockchaining Part 2</Link>
              <p className='pt-2 mt-2 text-sm font-light border-t'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam
              </p>

              <Link className='inline-block mt-4 font-medium text-indigo-500'>Read More</Link>
            </div>
          </Slider>
        </div>
      </section>

      <section className='pt-16 pb-24 mt-10 lg:pt-24 lg:pb-36 contact-section'>
        <div className='container text-center text-white'>

          <button className='inline-block w-24 h-24 p-3 mx-auto rounded-full bg-white/40'>
            <span className='flex items-center justify-center w-full h-full text-indigo-500 bg-white rounded-full'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
                <path fillRule="evenodd" d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z" clipRule="evenodd" />
              </svg>
            </span>
          </button>
          <h1 className='pt-2 text-3xl font-semibold text-center lg:text-5xl md:text-4xl'>
          Cloud Services <br className='hidden lg:block'/>
          and security implementation
          </h1>  
        </div>
      </section>


      <section className='pb-14'>
        <div className='container'>
          <div className='relative grid -mt-20 lg:grid-cols-2'>
              <div className='px-5 pt-8 pb-10 text-base font-light leading-7 md:pt-12 md:pb-24 md:px-14 Contact-box'>
                  <p className='text-left text-white'>
                  Our company specializes in providing customized solutions for a range of domains, including CRM, applications, mobile apps, AI, ML, data analysis, blockchain, AR/VR, game development, finance, and payments. We have a skilled team capable of meeting the technology needs of the cloud and various industries.
                  <br></br>
                  Here are some additional points about our company:
                  <br></br>
                  <ul>1. We offer resources located in the USA, Canada, UAE, and India (offshore).</ul>
                  <ul>2. We can provide support in any time zone.</ul>
                  <ul>3. We are available for both short-term and long-term contract periods.</ul>
                  <ul>4. We offer free resource management for our team.</ul>
                  </p>
              </div>
              <div className='p-5 md:p-10 bg-slate-800'>
                <p className='text-2xl font-semibold text-white lg:text-4xl md:text-3xl'>Get in Touch</p>
                <div className='grid grid-cols-2 gap-5 pt-5'>
                  <div>
                    <input type={'text'} className={`form-control`} placeholder="Name"/>
                  </div>
                  <div>
                    <input type={'text'} className={`form-control`} placeholder="Email"/>
                  </div>
                  <div className='col-span-full'>
                    <input type={'text'} className={`form-control`} placeholder="Phone"/>
                  </div>
                  <div className='col-span-full'>
                    <textarea type={'text'} className={`form-control`} placeholder="Message"></textarea>
                  </div>
                  <div className='col-span-full'>
                    <button className='inline-block px-8 py-2.5 mt-3 font-medium text-white uppercase rounded bg-gradient-to-r from-pink-500 to-indigo-500 hover:from-purple-500 hover:to-indigo-500'>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </section>
      

    </Layout>
  )
}


