import { Transition } from '@headlessui/react';
import React, { createRef, useState } from 'react';
import Footer from './Footer';
import Header from './Header';


export default function Layout({ children}) {
  const [headerActive, setHeaderActive] = useState(false);
  const scrollposition = createRef();
  const [isScrollTop, setIsScrollTop] = useState(false);
   
    const HandelScroll = () => {
      let getPosition = scrollposition.current.getBoundingClientRect();
      if(getPosition.y < -10){
        setHeaderActive(true)
      } else {
        setHeaderActive(false)
      }

      if(getPosition.y < -1000){
        setIsScrollTop(true)
      } else {
        setIsScrollTop(false)
      }
    }


  return (
    <section onScroll={() => HandelScroll()} className='h-screen scroll-smooth scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent scrollbar-thumb-rounded-md scrollbar-track-rounded-md'>
        <div id='banner'></div>
      <Header active={headerActive} />

      <section ref={scrollposition}>
          {children}
      </section>
        
      <Footer />
    
      <Transition
          show={isScrollTop}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className='fixed z-20 w-10 h-10 bottom-2 right-2'>
            <a href='#banner' className='flex items-center justify-center w-full h-full overflow-hidden text-white bg-indigo-700 rounded-t-2xl rounded-bl-2xl'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor" className="relative z-10 w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
              </svg>
            </a>
          </div>
      </Transition>

    </section>
  )
}

