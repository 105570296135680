import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { whiteLogo, logoOne, logoTwo, logoThree, logoFour } from '../../Assets';
import {FaFacebookF, } from 'react-icons/fa';
import {AiOutlineTwitter, AiFillLinkedin} from 'react-icons/ai';



export default function Footer() {

    const location = useLocation();
    const currentPath = location.pathname.split('/');
    

  return (
    <footer className='pt-0 pb-4 md:pb-10 mt-28 md:pt-14 bg-slate-800'>
        <div className='container'>
            <div className='flex justify-between max-w-4xl mx-auto bg-white rounded-lg shadow-lg p-7 -mt-28'>
                <div>
                    <img className='w-28' src={logoOne} alt={''} />
                </div>
                <div>
                    <img className='w-28' src={logoTwo} alt={''} />
                </div>
                <div>
                    <img className='w-28' src={logoThree} alt={''} />
                </div>
                <div>
                    <img className='w-28' src={logoFour} alt={''} />
                </div>
            </div>
            <div className='flex flex-col items-center justify-between pt-16 pb-6 md:flex-row lg:grid-cols-3'>
                <div className=''>
                    <img className='w-32' src={whiteLogo} alt={''} />
                </div>

                <div className='pt-8 md:pt-0'>
                    <ul className={`flex justify-center divide-x divide-white/40`}>
                        <li>
                            <Link to={`/`} className={`text-white inline-block px-7 hover:text-indigo-500`}>Home</Link>
                        </li>
                        <li>
                            {/* <Link to={`/services`} className={`text-white inline-block px-7 hover:text-indigo-500`}>Services</Link> */}
                            {currentPath?.[1] === '' ? (
                                <a href='#services' className={`text-white inline-block px-7 hover:text-indigo-500`}>Services</a>
                            ):(
                                <Link to={'/'} className={`text-white inline-block px-7 hover:text-indigo-500`}>Services</Link>
                            )}
                        </li>
                        <li>
                            <Link to={`/contact-us`} className={`text-white inline-block px-7 hover:text-indigo-500`}>Contact</Link>
                        </li>
                        <li>
                            <Link to={`/privacy`} className={`text-white inline-block px-7 hover:text-indigo-500`}>Privacy Policy</Link>
                        </li>
                    </ul>
                    <p className='pt-5 font-light text-center text-white/50'>© Copyright 2023, SoftGems Technologies</p>
                </div>

                <div className='pt-10 md:pt-0'>
                    <ul className='flex items-center justify-end space-x-3'>
                        <li>
                            <a href='#' rel="noreferrer" target="_blank" className='text-white transition-all hover:text-indigo-500'>
                                <FaFacebookF size={20}/>
                            </a>
                        </li>
                        <li>
                            <a href='#' target="_blank" className='text-white transition-all hover:text-indigo-500'>
                                <AiOutlineTwitter size={23}/>
                            </a>
                        </li>
                        
                        <li>
                            <a href='#' target="_blank" className='text-white transition-all hover:text-indigo-500'>
                                <AiFillLinkedin size={22}/>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        
    </footer>

  )
}
