
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './App.css';
import { ContactUs, HomePage, Privacy, Services } from './Pages';
import MissingRoute from './Pages/MissingRoute';


const App = () =>{
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={ <HomePage/> } />
        <Route path="/services" element={ <Services/> } />
        <Route path="/contact-us" element={ <ContactUs/> } />
        <Route path="/privacy" element={ <Privacy/> } />
        <Route path="*" element={<MissingRoute/>} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;
