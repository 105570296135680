import React, { Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FullLogo, LogoIcon } from '../../Assets';
import {SiSkype} from 'react-icons/si';
import {FaFacebookF} from 'react-icons/fa';
import {AiOutlineTwitter, AiFillLinkedin} from 'react-icons/ai';
import { Popover, Transition } from '@headlessui/react';
import {HiOutlineMail} from 'react-icons/hi';
import {GoLocation} from 'react-icons/go';

const Header = ({active}) => {

    const location = useLocation();
    const currentPath = location.pathname.split('/');


    return (
       
        <header className={`sticky top-0 left-0 transition-all right-0 w-full z-30 bg-white ${active ? 'shadow-lg':''}`}>
            
            <div className='bg-indigo-500 sm:py-2 py-1.5'>
                <div className='container flex items-center justify-between'>

                    <ul className='flex items-center space-x-3 divide-x divide-white/30'>
                        <li className='flex'>
                            <a href='tel:+1 (604) 499-2474' className='inline-flex items-center text-xs text-white transition-all'>
                                <span className='block pr-2'><SiSkype size={16}/></span>
                                <span className='hidden lg:block'>+1 (604) 499-2474</span>
                            </a>
                        </li>

                        <li className='flex pl-3'>
                            <a href='mailto:dmin@softgems.net' className='inline-flex items-center text-xs text-white transition-all'>
                                <span className='block pr-2'><HiOutlineMail size={19}/></span>
                                <span className='hidden lg:block'>admin@softgems.net</span>
                            </a>
                        </li>

                        <li className='flex pl-3'>
                            <a target="_blank" href='https://www.google.com/maps/place/15731+92+Ave,+Surrey,+BC+V4N+3C3,+Canada/@49.170393,-122.7886999,17z/data=!3m1!4b1!4m6!3m5!1s0x5485d74947029c4f:0xb80e6ab751bd9ece!8m2!3d49.170393!4d-122.7865112!16s%2Fg%2F11crw2sq27' 
                            className='inline-flex items-center text-xs text-white transition-all'>
                                <span className='block pr-2'><GoLocation size={16}/></span>
                                <span className='hidden lg:block'>15731 92 Ave, Surrey, BC V4N 3C3</span>
                            </a>
                        </li>
                    </ul>

                    <ul className='flex items-center space-x-2'>
                        <li>
                            <a href='#' target="_blank" className='text-white transition-all'>
                                <FaFacebookF size={14}/>
                            </a>
                        </li>
                        <li>
                            <a href='#' target="_blank" className='text-white transition-all'>
                                <AiOutlineTwitter size={19}/>
                            </a>
                        </li>
                        <li>
                            <a href='#' target="_blank" className='text-white transition-all'>
                                <AiFillLinkedin size={18}/>
                            </a>
                        </li>
                        
                    </ul>
                </div>
            </div>

            <div className={`container  ${active ? 'py-2 sm:py-3':' py-3 sm:py-5'}`}>
                
                <Popover className="relative">
                    <div className="">
                        <div className="flex items-center justify-between">
                            <div className="flex justify-start ">
                                <Link to={'/'} className='inline-block'>
                                    <img className={`hidden md:block h-auto transition-all object-cover ${active ? 'w-36':'w-40'}`} src={FullLogo} alt=''/>
                                    <img className={`md:hidden block h-auto transition-all object-cover ${active ? 'w-7':'w-10'}`} src={LogoIcon} alt=''/>
                                </Link>
                            </div>
                            <div className=" md:hidden">
                                <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-700 bg-gray-100 rounded-md hover:bg-gray-100 hover:text-gray-500 ">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-7">
                                        <path fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10zm0 5.25a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75a.75.75 0 01-.75-.75z" clipRule="evenodd" />
                                    </svg>
                                </Popover.Button>
                            </div>
                            <Popover.Group as="nav" className="hidden space-x-12 md:flex">
                                <Link to={`/`} className={`text-sm text-gray-500 inline-block hover:text-indigo-500 ${currentPath?.[1] === '' ? "text-indigo-500":""}`}>Home</Link>
                                {currentPath?.[1] === '' ? (
                                    <a href='#services' className={`text-sm text-gray-500 inline-block hover:text-indigo-500 ${currentPath?.[1] === 'services' ? "text-indigo-500":""}`}>Services</a>
                                ):(
                                    <Link to={'/'} className={`text-sm text-gray-500 inline-block hover:text-indigo-500 ${currentPath?.[1] === 'services' ? "text-indigo-500":""}`}>Services</Link>
                                )}
                                
                                
                                <Link to={`/contact-us`} className={`text-sm text-gray-500 inline-block hover:text-indigo-500 ${currentPath?.[1] === 'contact-us' ? "text-indigo-500":""}`}>Contact</Link>
                            </Popover.Group>
                            <div className="items-center justify-end hidden md:flex ">
                                {/* <Link className='inline-block px-5 py-2 font-medium text-white uppercase rounded bg-gradient-to-r from-pink-500 to-indigo-500 hover:from-purple-500 hover:to-indigo-500'>
                                    get a quote
                                </Link> */}
                               
                            </div>
                        </div>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="duration-200 ease-out"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="duration-100 ease-in"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Popover.Panel focus className="absolute inset-x-0 top-0 p-2 transition origin-top-right transform md:hidden">
                        <div className="bg-white divide-y-2 rounded-lg shadow-lg divide-gray-50 ring-1 ring-black ring-opacity-5">
                            <div className="px-5 pt-5 pb-6">
                            <div className="flex items-center justify-between">
                                
                                <div className="-mr-2">
                                <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:bg-gray-100 hover:text-gray-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                        <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                                    </svg>
                                </Popover.Button>
                                </div>
                            </div>
                            
                            </div>
                            <div className="px-5 py-6 space-y-6">
                                <div className="flex flex-col space-y-4">
                                    <Link to={`/`} className={`text-sm text-gray-500 inline-block hover:text-indigo-500`}>Home</Link>
                                    {/* <Link to={`/services`} className={`text-sm text-gray-500 inline-block hover:text-indigo-500`}>Services</Link> */}
                                    {currentPath?.[1] === '' ? (
                                        <a href='#services' className={`text-sm text-gray-500 inline-block hover:text-indigo-500`}>Services</a>
                                    ):(
                                        <Link to={'/' } className={`text-sm text-gray-500 inline-block hover:text-indigo-500`}>Services</Link>
                                    )}
                                    <Link to={`/contact-us`} className={`text-sm text-gray-500 inline-block hover:text-indigo-500`}>Contact</Link>
                                </div>
                                <div>
                                    <Link className="flex items-center justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-gradient-to-r from-pink-500 to-indigo-500 hover:bg-indigo-700">
                                        Sign up
                                    </Link>
                                </div>
                            </div>
                        </div>
                        </Popover.Panel>
                    </Transition>
                </Popover>

            </div>
        </header>
            
    )
}

export default Header
